// store.js
export const store = {
  state: {
    themeClass: '',
    themeIndex: 0,
    themes: [{
        name: 'light',
        className: 'theme-light',
        bigImg: '@/assets/me.jpg',
      },
      {
        name: 'dark',
        className: 'theme-dark',
      },
      {
        name: 'cyan',
        className: 'theme-cyan',
      },
      {
        name: 'orange',
        mainColor: '',
        fontColor: ''
      },
    ],
  },
  setTheme(indexOrName) {
    if (typeof (indexOrName) == 'number') {
      // console.log('theme index', indexOrName);
      let index = indexOrName;
      if (this.state.themes.length > index) {
        console.log('set theme index', index);
        this.state.themeIndex = index;
      }
    } else {
      // console.log('theme name', indexOrName);
      let name = indexOrName;
      for (let i in this.state.themes) {
        let theme = this.state.themes[i];
        if (theme.name == name) {
          console.log('set theme name', name);
          this.state.themeIndex = i;
          break;
        }
      }
    }
    console.log('this.state.themeIndex', this.state.themeIndex);
    this.state.themeClass = this.state.themes[this.state.themeIndex].className;
  },
  setThemeColor(color) {
    this.state.themeColor = color;
  },
  getCurrentTheme() {
    return this.state.themes[this.state.themeIndex];
  },
};
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark-reasonable.css";
import "./plugins/axios";
import "./plugins/element";
import "./plugins/clipboard";
// import "./plugins/monaco";
// import "./plugins/vue-mavon-editor";
import "moment/locale/zh-cn";

Vue.config.productionTip = false;

// 在main.js定义自定义指令
Vue.directive("highlight", function(el) {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach((block) => {
    hljs.highlightBlock(block);
  });
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

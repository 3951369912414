import Vue from "vue";
import Router from "vue-router";

const host = process.env.VUE_APP_HOST_URL;
Vue.use(Router);

const defaultRouter = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("./views/Layout.vue"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home.vue"),
        },
        {
          path: "/note",
          name: "note",
          component: () => import("./views/Note.vue"),
        },
        {
          path: "/note/detail/:id",
          name: "noteDetail",
          component: () => import("./views/NoteDetail.vue"),
        },
        {
          path: "/note/detail/:id/edit",
          name: "noteEdit",
          meta: { requiresAuth: true },
          component: () => import("./views/NoteDetailEdit.vue"),
        },
        {
          path: "/note/tag",
          name: "noteTag",
          meta: { requiresAuth: true },
          component: () => import("./views/NoteTag.vue"),
        },
        {
          path: "/tools",
          name: "tools",
          component: () => import("./views/Tools.vue"),
        },
        {
          path: "/photo",
          name: "photo",
          component: () => import("./views/Photo.vue"),
        },
        {
          path: "/game",
          name: "game",
          component: () => import("./views/Game.vue"),
        },
        {
          path: "/sprite",
          name: "sprite",
          component: () => import("./views/Sprite.vue"),
        },
        {
          path: "/gallary",
          name: "gallary",
          component: () => import("./views/Gallary.vue"),
        },
        {
          path: "/gym",
          name: "gym",
          component: () => import("./views/Gym.vue"),
        },
        {
          path: "/css",
          name: "css",
          component: () => import("./views/CssDemo.vue"),
        },
        {
          path: "/about",
          name: "about",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import("./views/About.vue"),
        },
        {
          path: "/algorithm",
          name: "algorithm",
          component: () => import("./views/AlgorithmSort.vue"),
        },
        {
          path: "/login",
          name: "login",
          component: () => import("./views/Login.vue"),
        },
      ],
    },
    {
      path: "/note/editor/:id/edit",
      name: "editor",
      meta: { requiresAuth: true },
      component: () => import("./views/NoteDetailEdit.vue"),
    },
    {
      path: "/resume",
      name: "resume",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Resume.vue"),
    },
  ],
});

const checkLogin = async () => {
  // let token = cookie.get("bad-token");
  let resp = await Vue.axios.get(`${host}/auth/check`);
  console.log("check login token:", resp);
  if (resp.data.err == 0) {
    return true;
  }
  return false;
};

defaultRouter.beforeEach(async (to, from, next) => {
  console.log(to, from);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    let check = await checkLogin();
    if (!check) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }
  next(); // 确保一定要调用 next()
});

// const testRouter = new Router({
//   mode: "history",
//   routes: [
//     {
//       path: "/",
//       // component: () => import("./views/Metrix.vue"),
//       component: () => import("./views/Tools.vue")
//     }
//   ]
// });

export default defaultRouter;

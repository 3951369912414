<template>
  <div :class="this.store.themeClass">
    <TransitionFadeLeft>
      <router-view />
    </TransitionFadeLeft>
  </div>
</template>

<script>
import TransitionFadeLeft from "./components/transition/TransitionFadeLeft.vue";
import { store } from "./store";

// console.log(
//   store.getCurrentTheme(),
//   store.state.themes[store.state.themeIndex]
// );

export default {
  name: "app",
  data: () => {
    return {
      store: store.state,
    };
  },
  components: {
    TransitionFadeLeft,
  },
  methods: {},
};
</script>

<style lang="less">
// bc
@import "//at.alicdn.com/t/font_1075035_up1k8ah8zea.css"; // 收藏 icon
@import "//at.alicdn.com/t/font_80361_cyqsoe1z6dw.css"; // 原创 icon
@import "./themes/light.less";
@import "./themes/dark.less";
@import "./themes/cyan.less";

* {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei,
    Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  // font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  //   "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
// * {
//   transition: all 0.2s;
// }
html,
body {
  -webkit-font-smoothing: antialiased; // 字体平滑
}
</style>
